import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { BehaviorSubject, shareReplay} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PageData } from '../models/page-data.type';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageDataService {
  private _pageData$ = new BehaviorSubject<PageData | null>(null);
  pageData$ = this._pageData$.pipe(shareReplay(1));

  constructor(private http: HttpClient) {
  }

  getPageDataByEntityAndId(entityName: string, entityId: number | null) {
    return this.http.get<PageData>(`${environment.apiBase}/pageData/${entityName}`.concat(entityId ? `/${entityId}` : ''))
      .pipe(
        take(1),
      )
      .subscribe(pageData => {
        this._pageData$.next(pageData);
      });
  }

  getStaticPageDataByKey(key: string){
    let params = new HttpParams().set('key', key);

    return this.http.get<PageData>(`${environment.apiBase}/pageData/static`, { params })
      .pipe(
        take(1),
      )
      .subscribe(pageData => {
        this._pageData$.next(pageData);
      });
  }
}


